<template>
  <div>
  </div>
</template>

<script>

export default {
  name: "pageIndex",
  props: {},
  components:{},
  mounted() {

  },
  created() {
    this.$root.$session.logout();
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

